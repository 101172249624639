<template>
  <div class="modal-body pb-0">
    <div
      class="card card-lightblue card-outline collapsed-card"
      v-if="csDetalles.length > 0 || csDetallesExtras.length > 0"
    >
      <div class="card-header pt-2 pb-2">
        <h3 class="card-title">
          <i class="fa fa-truck text-dark"></i> Reporte Trabajo:
          {{
            " " + servicio.persona != null && servicio.persona != undefined
              ? servicio.persona.nombres + " " + servicio.persona.apellidos
              : ""
          }}
        </h3>
        <div class="card-tools">
          <button
            type="button"
            data-card-widget="collapse"
            data-toggle="tooltip"
            title="Collapse"
            class="btn btn-tool"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="card-body p-0" v-if="csDetalles.length > 0">
        <i class="fa fa-user-clock p-3 pb-1"></i> Hora Normal
        <table class="table table-bordered table-striped table-hover table-sm">
          <thead class="bg-dark">
            <tr class="text-center">
              <th class="col-1">Fecha Ini</th>
              <th class="col-1">Hora Ini</th>
              <th class="col-1">Fecha Fin</th>
              <th class="col-1">Hora Fin</th>
              <th class="col-1">Duración <small>(horas)</small></th>
              <th class="col-1">Tipo Tiempo</th>
              <th>Observaciones</th>
            </tr>
          </thead>
          <tbody id="tbody">
            <tr
              v-for="detalle_servicio in csDetalles"
              :key="detalle_servicio.id"
            >
              <td class="text-center">
                {{ detalle_servicio.fecha_ini }}
              </td>
              <td class="text-center">
                {{ detalle_servicio.hora_ini }}
              </td>
              <td class="text-center">
                {{ detalle_servicio.fecha_fin }}
              </td>
              <td class="text-center">
                {{ detalle_servicio.hora_fin }}
              </td>
              <td class="text-center text-nowrap">
                <div v-if="detalle_servicio.tiempo">
                  <div v-if="detalle_servicio.tiempo.cant_horas_mostrar">
                    {{ detalle_servicio.tiempo.cant_horas_mostrar }}
                  </div>
                </div>
                <div v-else><small class="text-muted">Sin establecer</small></div>
              </td>
              <td class="text-center">
                <span
                  class="badge"
                  :class="
                    detalle_servicio.tipo_tiempo == 1
                      ? 'badge-success'
                      : detalle_servicio.tipo_tiempo == 2
                      ? 'badge-warning'
                      : detalle_servicio.tipo_tiempo == 3
                      ? 'badge-danger'
                      : 'bg-secundary'
                  "
                >
                  {{ detalle_servicio.nTipoTiempo }}
                </span>
              </td>
              <td>{{ detalle_servicio.observaciones }}</td>
            </tr>
          </tbody>
          <tbody v-if="datos_barra">
            <tr class="bg-light">
              <td class="p-1" colspan="7">
                <div class="progress">
                  <div
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="progress-bar progress-bar-striped bg-success progress-bar-animated"
                    :style="`width: ${
                      datos_barra.operativo.porcentaje != 'NaN'
                        ? datos_barra.operativo.porcentaje
                        : 0
                    }%`"
                  >
                    <b>
                      {{
                        datos_barra.operativo.tiempo.years
                          ? `Años: ${datos_barra.operativo.tiempo.years}`
                          : ""
                      }}
                      {{
                        datos_barra.operativo.tiempo.meses
                          ? `Meses: ${datos_barra.operativo.tiempo.meses}`
                          : ""
                      }}
                      {{
                        datos_barra.operativo.tiempo.dias
                          ? `Días: ${datos_barra.operativo.tiempo.dias}`
                          : ""
                      }}
                      {{
                        datos_barra.operativo.tiempo.horas
                          ? `Horas: ${datos_barra.operativo.tiempo.horas}`
                          : ""
                      }}
                      {{
                        datos_barra.operativo.tiempo.minutos
                          ? `Minutos: ${datos_barra.operativo.tiempo.minutos}`
                          : ""
                      }}
                      ({{
                        datos_barra.operativo.porcentaje != "NaN"
                          ? datos_barra.operativo.porcentaje
                          : 0
                      }}%)
                    </b>
                  </div>
                  <div
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="progress-bar progress-bar-striped bg-warning progress-bar-animated"
                    :style="`width: ${
                      datos_barra.disponible.porcentaje != 'NaN'
                        ? datos_barra.disponible.porcentaje
                        : 0
                    }%`"
                  >
                    <b>
                      {{
                        datos_barra.disponible.tiempo.years
                          ? `Años: ${datos_barra.disponible.tiempo.years}`
                          : ""
                      }}
                      {{
                        datos_barra.disponible.tiempo.meses
                          ? `Meses: ${datos_barra.disponible.tiempo.meses}`
                          : ""
                      }}
                      {{
                        datos_barra.disponible.tiempo.dias
                          ? `Días: ${datos_barra.disponible.tiempo.dias}`
                          : ""
                      }}
                      {{
                        datos_barra.disponible.tiempo.horas
                          ? `Horas: ${datos_barra.disponible.tiempo.horas}`
                          : ""
                      }}
                      {{
                        datos_barra.disponible.tiempo.minutos
                          ? `Minutos: ${datos_barra.disponible.tiempo.minutos}`
                          : ""
                      }}
                      ({{
                        datos_barra.disponible.porcentaje != "NaN"
                          ? datos_barra.disponible.porcentaje
                          : 0
                      }}%)
                    </b>
                  </div>
                  <div
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                    :style="`width: ${
                      datos_barra.indisponible.porcentaje != 'NaN'
                        ? datos_barra.indisponible.porcentaje
                        : 0
                    }%`"
                  >
                    <b>
                      {{
                        datos_barra.indisponible.tiempo.years
                          ? `Años: ${datos_barra.indisponible.tiempo.years}`
                          : ""
                      }}
                      {{
                        datos_barra.indisponible.tiempo.meses
                          ? `Meses: ${datos_barra.indisponible.tiempo.meses}`
                          : ""
                      }}
                      {{
                        datos_barra.indisponible.tiempo.dias
                          ? `Días: ${datos_barra.indisponible.tiempo.dias}`
                          : ""
                      }}
                      {{
                        datos_barra.indisponible.tiempo.horas
                          ? `Horas: ${datos_barra.indisponible.tiempo.horas}`
                          : ""
                      }}
                      {{
                        datos_barra.indisponible.tiempo.minutos
                          ? `Minutos: ${datos_barra.indisponible.tiempo.minutos}`
                          : ""
                      }}
                      ({{
                        datos_barra.indisponible.porcentaje != "NaN"
                          ? datos_barra.indisponible.porcentaje
                          : 0
                      }}%)
                    </b>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body p-0" v-if="csDetallesExtras.length > 0">
        <i class="fa fa-user-clock p-3 pb-1 text-info lg"></i> Hora Extra
        <table class="table table-bordered table-striped table-hover table-sm">
          <thead class="bg-dark">
            <tr class="text-center">
              <th class="col-1">Fecha Ini</th>
              <th class="col-1">Hora Ini</th>
              <th class="col-1">Fecha Fin</th>
              <th class="col-1">Hora Fin</th>
              <th class="col-1">Duración <small>(horas)</small></th>
              <th class="col-1">Tipo Tiempo</th>
              <th>Observaciones</th>
            </tr>
          </thead>
          <tbody id="tbody">
            <tr
              v-for="detalle_servicioextra in csDetallesExtras"
              :key="detalle_servicioextra.id"
            >
              <td class="text-center">
                {{ detalle_servicioextra.fecha_ini }}
              </td>
              <td class="text-center">
                {{ detalle_servicioextra.hora_ini }}
              </td>
              <td class="text-center">
                {{ detalle_servicioextra.fecha_fin }}
              </td>
              <td class="text-center">
                {{ detalle_servicioextra.hora_fin }}
              </td>
              <td class="text-center text-nowrap">
                <div v-if="detalle_servicioextra.tiempo">
                  <div v-if="detalle_servicioextra.tiempo.cant_horas_mostrar">
                    {{ detalle_servicioextra.tiempo.cant_horas_mostrar }}
                  </div>
                </div>
                <div v-else><small class="text-muted">Sin establecer</small></div>
              </td>
              <td class="text-center">
                <span
                  class="badge"
                  :class="
                    detalle_servicioextra.tipo_tiempo == 1
                      ? 'badge-success'
                      : detalle_servicioextra.tipo_tiempo == 2
                      ? 'badge-warning'
                      : detalle_servicioextra.tipo_tiempo == 3
                      ? 'badge-danger'
                      : 'bg-secundary'
                  "
                >
                  {{ detalle_servicioextra.nTipoTiempo }}
                </span>
              </td>
              <td>{{ detalle_servicioextra.observaciones }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="card card-lightblue card-outline collapsed-card"
      v-if="csDetalles2.length > 0 || csDetallesExtras2.length > 0"
    >
      <div class="card-header pt-2 pb-2">
        <h3 class="card-title">
          <i class="fa fa-truck text-dark"></i> Reporte Trabajo Segundo
          Conductor:
          {{
            " " + servicio.persona_dos != null && servicio.persona_dos != undefined
              ? servicio.persona_dos.nombres + " " + servicio.persona_dos.apellidos
              : " "
          }}
        </h3>
        <div class="card-tools">
          <button
            type="button"
            data-card-widget="collapse"
            data-toggle="tooltip"
            title="Collapse"
            class="btn btn-tool"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="card-body p-0" v-if="csDetalles2.length > 0">
        <i class="fa fa-user-clock p-3 pb-1"></i> Hora Normal
        <table class="table table-bordered table-striped table-hover table-sm">
          <thead class="bg-dark">
            <tr class="text-center">
              <th class="col-1">Fecha Ini</th>
              <th class="col-1">Hora Ini</th>
              <th class="col-1">Fecha Fin</th>
              <th class="col-1">Hora Fin</th>
              <th class="col-1">Duración <small>(horas)</small></th>
              <th class="col-1">Tipo Tiempo</th>
              <th>Observaciones</th>
            </tr>
          </thead>
          <tbody id="tbody">
            <tr
              v-for="detalle_servicio in csDetalles2"
              :key="detalle_servicio.id"
            >
              <td class="text-center">
                {{ detalle_servicio.fecha_ini }}
              </td>
              <td class="text-center">
                {{ detalle_servicio.hora_ini }}
              </td>
              <td class="text-center">
                {{ detalle_servicio.fecha_fin }}
              </td>
              <td class="text-center">
                {{ detalle_servicio.hora_fin }}
              </td>
              <td class="text-center text-nowrap">
                <div v-if="detalle_servicio.tiempo">
                  <div v-if="detalle_servicio.tiempo.cant_horas_mostrar">
                    {{ detalle_servicio.tiempo.cant_horas_mostrar }}
                  </div>
                </div>
                <div v-else><small class="text-muted">Sin establecer</small></div>
              </td>
              <td class="text-center">
                <span
                  class="badge"
                  :class="
                    detalle_servicio.tipo_tiempo == 1
                      ? 'badge-success'
                      : detalle_servicio.tipo_tiempo == 2
                      ? 'badge-warning'
                      : detalle_servicio.tipo_tiempo == 3
                      ? 'badge-danger'
                      : 'bg-secundary'
                  "
                >
                  {{ detalle_servicio.nTipoTiempo }}
                </span>
              </td>
              <td>{{ detalle_servicio.observaciones }}</td>
            </tr>
          </tbody>
          <tbody v-if="datos_barra2">
            <tr class="bg-light">
              <td class="p-1" colspan="7">
                <div class="progress">
                  <div
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="progress-bar progress-bar-striped bg-success progress-bar-animated"
                    :style="`width: ${
                      datos_barra2.operativo.porcentaje != 'NaN'
                        ? datos_barra2.operativo.porcentaje
                        : 0
                    }%`"
                  >
                    <b>
                      {{
                        datos_barra2.operativo.tiempo.years
                          ? `Años: ${datos_barra2.operativo.tiempo.years}`
                          : ""
                      }}
                      {{
                        datos_barra2.operativo.tiempo.meses
                          ? `Meses: ${datos_barra2.operativo.tiempo.meses}`
                          : ""
                      }}
                      {{
                        datos_barra2.operativo.tiempo.dias
                          ? `Días: ${datos_barra2.operativo.tiempo.dias}`
                          : ""
                      }}
                      {{
                        datos_barra2.operativo.tiempo.horas
                          ? `Horas: ${datos_barra2.operativo.tiempo.horas}`
                          : ""
                      }}
                      {{
                        datos_barra2.operativo.tiempo.minutos
                          ? `Minutos: ${datos_barra2.operativo.tiempo.minutos}`
                          : ""
                      }}
                      ({{
                        datos_barra2.operativo.porcentaje != "NaN"
                          ? datos_barra2.operativo.porcentaje
                          : 0
                      }}%)
                    </b>
                  </div>
                  <div
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="progress-bar progress-bar-striped bg-warning progress-bar-animated"
                    :style="`width: ${
                      datos_barra2.disponible.porcentaje != 'NaN'
                        ? datos_barra2.disponible.porcentaje
                        : 0
                    }%`"
                  >
                    <b>
                      {{
                        datos_barra2.disponible.tiempo.years
                          ? `Años: ${datos_barra2.disponible.tiempo.years}`
                          : ""
                      }}
                      {{
                        datos_barra2.disponible.tiempo.meses
                          ? `Meses: ${datos_barra2.disponible.tiempo.meses}`
                          : ""
                      }}
                      {{
                        datos_barra2.disponible.tiempo.dias
                          ? `Días: ${datos_barra2.disponible.tiempo.dias}`
                          : ""
                      }}
                      {{
                        datos_barra2.disponible.tiempo.horas
                          ? `Horas: ${datos_barra2.disponible.tiempo.horas}`
                          : ""
                      }}
                      {{
                        datos_barra2.disponible.tiempo.minutos
                          ? `Minutos: ${datos_barra2.disponible.tiempo.minutos}`
                          : ""
                      }}
                      ({{
                        datos_barra2.disponible.porcentaje != "NaN"
                          ? datos_barra2.disponible.porcentaje
                          : 0
                      }}%)
                    </b>
                  </div>
                  <div
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                    :style="`width: ${
                      datos_barra2.indisponible.porcentaje != 'NaN'
                        ? datos_barra2.indisponible.porcentaje
                        : 0
                    }%`"
                  >
                    <b>
                      {{
                        datos_barra2.indisponible.tiempo.years
                          ? `Años: ${datos_barra2.indisponible.tiempo.years}`
                          : ""
                      }}
                      {{
                        datos_barra2.indisponible.tiempo.meses
                          ? `Meses: ${datos_barra2.indisponible.tiempo.meses}`
                          : ""
                      }}
                      {{
                        datos_barra2.indisponible.tiempo.dias
                          ? `Días: ${datos_barra2.indisponible.tiempo.dias}`
                          : ""
                      }}
                      {{
                        datos_barra2.indisponible.tiempo.horas
                          ? `Horas: ${datos_barra2.indisponible.tiempo.horas}`
                          : ""
                      }}
                      {{
                        datos_barra2.indisponible.tiempo.minutos
                          ? `Minutos: ${datos_barra2.indisponible.tiempo.minutos}`
                          : ""
                      }}
                      ({{
                        datos_barra2.indisponible.porcentaje != "NaN"
                          ? datos_barra2.indisponible.porcentaje
                          : 0
                      }}%)
                    </b>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body p-0" v-if="csDetallesExtras2.length > 0">
        <i class="fa fa-user-clock p-3 pb-1 text-info lg"></i> Hora Extra
        <table class="table table-bordered table-striped table-hover table-sm">
          <thead class="bg-dark">
            <tr class="text-center">
              <th class="col-1">Fecha Ini</th>
              <th class="col-1">Hora Ini</th>
              <th class="col-1">Fecha Fin</th>
              <th class="col-1">Hora Fin</th>
              <th class="col-1">Duración <small>(horas)</small></th>
              <th class="col-1">Tipo Tiempo</th>
              <th>Observaciones</th>
            </tr>
          </thead>
          <tbody id="tbody">
            <tr
              v-for="detalle_servicioextra in csDetallesExtras2"
              :key="detalle_servicioextra.id"
            >
              <td class="text-center">
                {{ detalle_servicioextra.fecha_ini }}
              </td>
              <td class="text-center">
                {{ detalle_servicioextra.hora_ini }}
              </td>
              <td class="text-center">
                {{ detalle_servicioextra.fecha_fin }}
              </td>
              <td class="text-center">
                {{ detalle_servicioextra.hora_fin }}
              </td>
              <td class="text-center text-nowrap">
                <div v-if="detalle_servicioextra.tiempo">
                  <div v-if="detalle_servicioextra.tiempo.cant_horas_mostrar">
                    {{ detalle_servicioextra.tiempo.cant_horas_mostrar }}
                  </div>
                </div>
                <div v-else><small class="text-muted">Sin establecer</small></div>
              </td>
              <td class="text-center">
                <span
                  class="badge"
                  :class="
                    detalle_servicioextra.tipo_tiempo == 1
                      ? 'badge-success'
                      : detalle_servicioextra.tipo_tiempo == 2
                      ? 'badge-warning'
                      : detalle_servicioextra.tipo_tiempo == 3
                      ? 'badge-danger'
                      : 'bg-secundary'
                  "
                >
                  {{ detalle_servicioextra.nTipoTiempo }}
                </span>
              </td>
              <td>{{ detalle_servicioextra.observaciones }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="servicio.estado >= 3" class="row justify-content-center">
      <div
        class="col-md-4"
        v-for="(firmante, index) in firmasData"
        :key="index"
      >
        <div class="card" style="min-height: 25em">
          <div class="card-body pb-0">
            <h5>{{ firmante.tipo_firma.descripcion }}</h5>
            <!-- Formulario para firmar -->
            <div
              class="row"
              v-if="
                (firmante.permiso &&
                  !firmante.firma &&
                  firmante.ultima_firma.posicion == index &&
                  firmante.tipo_firma.cod_alterno == 'firmaSolicitante') ||
                (firmante.permiso &&
                  !firmante.firma &&
                  firmante.firma_anterior &&
                  firmante.tipo_firma.cod_alterno == 'firmaSolicitante')
              "
            >
              <div class="form-group col-md-12">
                <div class="callout callout-warning">
                  <small
                    ><strong>
                      {{
                        $store.getters.getAuthAzure
                          ? `La sesión esta iniciada con Azure.`
                          : `Para aprobar el servicio, por favor digite su clave.`
                      }}
                    </strong></small
                  >
                </div>
              </div>
              <div
                class="form-group col-md-6"
                v-if="!$store.getters.getAuthAzure"
              >
                <label for="sign_password">Contraseña</label>
                <input
                  type="password"
                  class="form-control form-control-sm"
                  v-model="form.pass"
                  :class="$v.form.pass.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div
                class="form-group col-md-6"
                v-if="!$store.getters.getAuthAzure"
              >
                <label for="re_sign_password">Confirme Contraseña</label>
                <input
                  type="password"
                  class="form-control form-control-sm"
                  v-model="form.re_pass"
                  :class="$v.form.re_pass.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-12">
                <label for="observaciones">Observación</label>
                <textarea
                  vtype="date"
                  rows="2"
                  class="form-control form-control-sm"
                  v-model="form.observacion"
                  :class="
                    $v.form.observacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                ></textarea>
              </div>
              <div class="form-group col-md-12">
                <button
                  type="button"
                  title="Guardar firma"
                  data-toggle="tooltip"
                  class="btn btn-sm bg-success"
                  @click="save()"
                  v-if="!$v.form.$invalid"
                >
                  <i class="fas fa-signature"></i>
                  <div>
                    <span><small>Aprobar Servicio</small></span>
                  </div>
                </button>
              </div>
            </div>
            <!-- Mensaje de pendiente de firma -->
            <div
              class="row"
              v-if="
                (!firmante.firma && !firmante.permiso) ||
                (!firmante.firma &&
                  !firmante.firma_anterior &&
                  firmante.ultima_firma.posicion != index) ||
                (firmante.tipo_firma.cod_alterno != 'firmaSolicitante' &&
                  !firmante.firma)
              "
            >
              <div class="col-md-12">
                <div class="callout callout-warning m-0">
                  <small
                    ><strong>
                      A la espera de aprobación del
                      {{ firmante.ultima_firma.descripcion }}.
                    </strong></small
                  >
                </div>
              </div>
            </div>
            <!-- Cuando ya está firmado -->
            <div class="row" v-if="firmante.firma && firmante.firma.user">
              <div class="col-md-12">
                <p>
                  Firmado por: <br />
                  <strong>{{ firmante.firma.user.name }}</strong>
                </p>
                <p
                  class="text-center mr-5 ml-5"
                  v-if="firmante.firma.user.link_firma"
                >
                  <img
                    class="img-fluid"
                    :src="uri_docs + firmante.firma.user.link_firma"
                  />
                </p>
                <p>
                  Fecha: <br />
                  <strong>{{ firmante.firma.fecha_firma }}</strong>
                </p>
                <p>
                  Observación: <br />
                  {{ firmante.firma.observacion }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "CsSolicitudesInternasFirmas",

  props: {
    // Declaración de la propiedad servicio como prop
    servicio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      datos_barra: null,
      datos_barra2: null,
      csDetalles: [],
      csDetalles2: [],
      csDetallesExtras: [],
      csDetallesExtras2: [],
      firmasData: [],
      form: {},
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    if (!this.$store.getters.getAuthAzure) {
      return {
        form: {
          pass: {
            required,
          },
          re_pass: {
            required,
            sameAsPassword: sameAs("pass"),
          },
          observacion: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          observacion: {
            required,
          },
        },
      };
    }
  },

  methods: {
    async getIndex() {
      this.limpiarModal();
      this.csDetalles = this.servicio.detalle_servicios.filter(
        (det) =>
          det.tipo_hora == 1 && det.conductor_id === this.servicio.persona_id
      );
      this.csDetallesExtras = this.servicio.detalle_servicios.filter(
        (det) =>
          det.tipo_hora == 2 && det.conductor_id == this.servicio.persona_id
      );
      this.csDetalles2 = this.servicio.detalle_servicios.filter(
        (det) =>
          det.tipo_hora == 1 && det.conductor_id == this.servicio.persona_2_id
      );
      this.csDetallesExtras2 = this.servicio.detalle_servicios.filter(
        (det) =>
          det.tipo_hora == 2 && det.conductor_id == this.servicio.persona_2_id
      );
      await this.getDatosBarra();

      /****
       * Se determina los formularios para firma de acuerdo con el tipo de servicio
       */
      let tipo_firmante = [];
      switch (this.servicio.tipo_servicio) {
        case 1: // Es transporte
          tipo_firmante = [4, 5, 6];
          break;

        case 2: // Es Izaje
          tipo_firmante = [4, 5, 6];
          break;
      }

      this.firmasData = [];
      let ultima_firma = this.$parent.firmantes.find(
        (f) => f.numeracion == tipo_firmante[0]
      );

      tipo_firmante.forEach((fir, index) => {
        // Se valida el tipo de firma del servicio
        let tipo_firma = this.$parent.firmantes.find(
          (f) => f.numeracion == fir
        );

        // Se valida si ya exite firma
        let firma = this.servicio.firmas.find((f) => f.tipo_firma == fir);

        // Se establece quien hace la ultima firma
        if (firma) {
          ultima_firma = this.$parent.firmantes.find(
            (f) => f.numeracion == tipo_firmante[index]
          );
        }

        ultima_firma.posicion = firma ? index : 0;

        // Se valida si hay firma anterior
        let firma_anterior = this.servicio.firmas.find(
          (f) => f.tipo_firma == tipo_firmante[index - 1]
        )
          ? true
          : false;

        // Se valida si el usuario tiene permisos
        let permiso = this.$store.getters.can(
          `cs.serviciosInterno.${tipo_firma.cod_alterno}`
        )
          ? true
          : false;

        this.firmasData.push({
          tipo_firma,
          firma,
          firma_anterior,
          ultima_firma,
          permiso,
        });
        // Se valida la creacion de los datos del formulario para firmar
        if (
          (permiso && !firma && ultima_firma.posicion == index) ||
          (permiso && !firma && firma_anterior)
        ) {
          this.form = {
            id: null,
            tipo_firma: tipo_firma.numeracion,
            cs_servicio_interno_id: this.servicio.id,
            pass: null,
            re_pass: null,
            observacion: null,
          };
        }
      });
    },

    getTiempo(hora_ini, hora_fin) {
      if (hora_ini && hora_fin) {
        let inicio = moment(hora_ini);
        let fin = moment(hora_fin);
        let diferencia = fin.diff(inicio);

        // Se calcula la cantidad en horas según requerimiento de Frontera
        const cant_horas = diferencia / 1000 / 60 / 60;
        const cant_minutos = (cant_horas - parseInt(cant_horas)) * 10;

        return {
          years: moment.duration(diferencia).years(),
          meses: moment.duration(diferencia).months(),
          dias: moment.duration(diferencia).days(),
          horas: moment.duration(diferencia).hours(),
          minutos: moment.duration(diferencia).minutes(),
          cant_horas_mostrar:
            cant_minutos < 5 ? parseInt(cant_horas) : parseInt(cant_horas + 1),
        };
      }
      return null;
    },

    async getDatosBarra() {
      this.datos_barra = {
        operativo: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
        disponible: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
        indisponible: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
      };

      this.csDetalles.forEach((det) => {
        // Se calcula el tiempo del reporte
        const inicio_reporte = new Date(`${det.hora_ini}`);
        const fin_reporte = new Date(`${det.hora_fin}`);
        const tiempo_reporte = fin_reporte.getTime() - inicio_reporte.getTime();

        switch (det.tipo_tiempo) {
          case 1:
            this.datos_barra.operativo.cant += tiempo_reporte;
            break;
          case 2:
            this.datos_barra.disponible.cant += tiempo_reporte;
            break;
          case 3:
            this.datos_barra.indisponible.cant += tiempo_reporte;
            break;
          default:
            break;
        }
      });

      this.csDetalles.forEach((det) => {
        const inicio_f = det.hora_ini.split(" ");
        const fin_f = det.hora_fin.split(" ");
        det.fecha_ini = inicio_f[0];
        det.hora_ini = inicio_f[1];
        det.fecha_fin = fin_f[0];
        det.hora_fin = fin_f[1];
        det.tiempo = this.getTiempo(
          `${det.fecha_ini} ${det.hora_ini}`,
          `${det.fecha_fin} ${det.hora_fin}`
        );
      });

      this.csDetallesExtras.forEach((det) => {
        const inicio_f = det.hora_ini.split(" ");
        const fin_f = det.hora_fin.split(" ");
        det.fecha_ini = inicio_f[0];
        det.hora_ini = inicio_f[1];
        det.fecha_fin = fin_f[0];
        det.hora_fin = fin_f[1];
        det.tiempo = this.getTiempo(
          `${det.fecha_ini} ${det.hora_ini}`,
          `${det.fecha_fin} ${det.hora_fin}`
        );
      });

      this.csDetallesExtras2.forEach((det) => {
        const inicio_f = det.hora_ini.split(" ");
        const fin_f = det.hora_fin.split(" ");
        det.fecha_ini = inicio_f[0];
        det.hora_ini = inicio_f[1];
        det.fecha_fin = fin_f[0];
        det.hora_fin = fin_f[1];
        det.tiempo = this.getTiempo(
          `${det.fecha_ini} ${det.hora_ini}`,
          `${det.fecha_fin} ${det.hora_fin}`
        );
      });

      let fechaIni = this.servicio.fecha_ini.split(" ");
      const inicio_servicio = new Date(`${this.servicio.fecha_ini}`);
      const fin_servicio = new Date(`${fechaIni[0]} ${"18:00"}`);
      const tiempo_servicio =
        fin_servicio.getTime() - inicio_servicio.getTime();

      this.datos_barra.operativo.porcentaje = this.toFixed(
        (this.datos_barra.operativo.cant * 100) / tiempo_servicio
      );

      this.datos_barra.disponible.porcentaje = this.toFixed(
        (this.datos_barra.disponible.cant * 100) / tiempo_servicio
      );

      this.datos_barra.indisponible.porcentaje = this.toFixed(
        (this.datos_barra.indisponible.cant * 100) / tiempo_servicio
      );

      // Se asignan los tiempos
      this.datos_barra.operativo.tiempo = {
        years: moment.duration(this.datos_barra.operativo.cant).years(),
        meses: moment.duration(this.datos_barra.operativo.cant).months(),
        dias: moment.duration(this.datos_barra.operativo.cant).days(),
        horas: moment.duration(this.datos_barra.operativo.cant).hours(),
        minutos: moment.duration(this.datos_barra.operativo.cant).minutes(),
      };

      this.datos_barra.disponible.tiempo = {
        years: moment.duration(this.datos_barra.disponible.cant).years(),
        meses: moment.duration(this.datos_barra.disponible.cant).months(),
        dias: moment.duration(this.datos_barra.disponible.cant).days(),
        horas: moment.duration(this.datos_barra.disponible.cant).hours(),
        minutos: moment.duration(this.datos_barra.disponible.cant).minutes(),
      };

      this.datos_barra.indisponible.tiempo = {
        years: moment.duration(this.datos_barra.indisponible.cant).years(),
        meses: moment.duration(this.datos_barra.indisponible.cant).months(),
        dias: moment.duration(this.datos_barra.indisponible.cant).days(),
        horas: moment.duration(this.datos_barra.indisponible.cant).hours(),
        minutos: moment.duration(this.datos_barra.indisponible.cant).minutes(),
      };

      this.datos_barra2 = {
        operativo: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
        disponible: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
        indisponible: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
      };

      this.csDetalles2.forEach((det) => {
        // Se calcula el tiempo del reporte
        const inicio_reporte = new Date(`${det.hora_ini}`);
        const fin_reporte = new Date(`${det.hora_fin}`);
        const tiempo_reporte = fin_reporte.getTime() - inicio_reporte.getTime();

        switch (det.tipo_tiempo) {
          case 1:
            this.datos_barra2.operativo.cant += tiempo_reporte;
            break;
          case 2:
            this.datos_barra2.disponible.cant += tiempo_reporte;
            break;
          case 3:
            this.datos_barra2.indisponible.cant += tiempo_reporte;
            break;
          default:
            break;
        }
      });

      this.csDetalles2.forEach((det) => {
        const inicio_f = det.hora_ini.split(" ");
        const fin_f = det.hora_fin.split(" ");
        det.fecha_ini = inicio_f[0];
        det.hora_ini = inicio_f[1];
        det.fecha_fin = fin_f[0];
        det.hora_fin = fin_f[1];
        det.tiempo = this.getTiempo(
          `${det.fecha_ini} ${det.hora_ini}`,
          `${det.fecha_fin} ${det.hora_fin}`
        );
      });

      // Se calcula el tiempo del servicio
      let fechaIni2 = this.servicio.fecha_ini.split(" ");
      const inicio_servicio2 = new Date(`${fechaIni2[0]} ${"18:00"}`);
      const fin_servicio2 = new Date(`${this.servicio.fecha_fin}`);
      const tiempo_servicio2 =
        fin_servicio2.getTime() - inicio_servicio2.getTime();

      this.datos_barra2.operativo.porcentaje = this.toFixed(
        (this.datos_barra2.operativo.cant * 100) / tiempo_servicio2
      );
      this.datos_barra2.disponible.porcentaje = this.toFixed(
        (this.datos_barra2.disponible.cant * 100) / tiempo_servicio2
      );
      this.datos_barra2.indisponible.porcentaje = this.toFixed(
        (this.datos_barra2.indisponible.cant * 100) / tiempo_servicio2
      );

      // Se asignan los tiempos
      this.datos_barra2.operativo.tiempo = {
        years: moment.duration(this.datos_barra2.operativo.cant).years(),
        meses: moment.duration(this.datos_barra2.operativo.cant).months(),
        dias: moment.duration(this.datos_barra2.operativo.cant).days(),
        horas: moment.duration(this.datos_barra2.operativo.cant).hours(),
        minutos: moment.duration(this.datos_barra2.operativo.cant).minutes(),
      };
      this.datos_barra2.disponible.tiempo = {
        years: moment.duration(this.datos_barra2.disponible.cant).years(),
        meses: moment.duration(this.datos_barra2.disponible.cant).months(),
        dias: moment.duration(this.datos_barra2.disponible.cant).days(),
        horas: moment.duration(this.datos_barra2.disponible.cant).hours(),
        minutos: moment.duration(this.datos_barra2.disponible.cant).minutes(),
      };
      this.datos_barra2.indisponible.tiempo = {
        years: moment.duration(this.datos_barra2.indisponible.cant).years(),
        meses: moment.duration(this.datos_barra2.indisponible.cant).months(),
        dias: moment.duration(this.datos_barra2.indisponible.cant).days(),
        horas: moment.duration(this.datos_barra2.indisponible.cant).hours(),
        minutos: moment.duration(this.datos_barra2.indisponible.cant).minutes(),
      };
    },

    limpiarModal() {
      this.form = {};
      this.csDetalles = [];
      this.csDetallesExtras = [];
      this.csDetalles2 = [];
      this.csDetallesExtras2 = [];
    },

    toFixed(num) {
      return Number.parseFloat(num).toFixed(2);
    },

    save() {
      const me = this;
      this.$parent.$parent.cargando = true;
      // Se complementa los datos del usuario
      this.form.loginAzure = this.$store.getters.getAuthAzure;
      this.form.user_id = this.$store.getters.getUser.id;

      let data = {
        form: this.form,
        cant_firmas: this.firmasData.length,
      };

      axios
        .post("/api/cs/servicios/firmas", data)
        .then((response) => {
          if (!response.data.user_error) {
            me.$parent.$parent.cargando = false;
            me.$parent.getIndex(me.$parent.page);

            me.$swal({
              icon: "success",
              title: "El servicio fue aprobado exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            me.$parent.$parent.cargando = false;
            me.$swal({
              icon: "error",
              title: response.data.user_error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          me.$parent.$parent.cargando = false;
        });
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
